import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import tealSprite60 from './img/teal-sprite-60.png'

export default class AppLoadingScreen extends React.PureComponent {
  renderSprite() {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%) scale(0.5)',
        }}
      >
        <div
          style={{
            position: 'relative',
            width: SPRITE_WIDTH,
            height: SPRITE_HEIGHT,
            overflow: 'hidden',
          }}
        >
          <img
            src={tealSprite60}
            style={{
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
              width: SPRITESHEET_WIDTH,
              height: SPRITESHEET_HEIGHT,
              maxWidth: 'none',
              animation:
                'tw-app-loading-screen__loadingAnimation 2.5s linear infinite',
            }}
          />
        </div>
      </div>
    )
  }

  renderInformation() {
    return (
      <div
        style={{
          position: 'absolute',
          bottom: '25px',
          left: '50%',
          transform: 'translate(-50%)',
          color: 'white',
          whiteSpace: 'nowrap',
        }}
      >
        <span>
          <span id="app-loading-status">Loading…</span>
        </span>
      </div>
    )
  }

  render = () => {
    return (
      <div
        className="tw-app-loading-screen"
        style={{
          backgroundColor: 'white',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <style>{keyframes}</style>
        {this.renderSprite()}
        {this.renderInformation()}
      </div>
    )
  }
}

const SPRITE_WIDTH = 225
const SPRITE_HEIGHT = 140
const SPRITESHEET_WIDTH = 1800
const SPRITESHEET_HEIGHT = 1820

const SPRITE_COLUMNS = 8

const FRAMES_COUNT = 160 // DOUBLE THE TROUBLE

const FREEZE_START = 56
const FREEZE_END = 77
const FREEZED_FRAMES = FREEZE_END - (FREEZE_START + 1)

const frames = Array(FRAMES_COUNT + 1)
  .fill(null)
  .map((v, i) => {
    const index =
      i <= FREEZE_START ? i : i < FREEZE_END ? FREEZE_START : i - FREEZED_FRAMES
    const x = (index % SPRITE_COLUMNS) * SPRITE_WIDTH
    const y = ~~(index / SPRITE_COLUMNS) * SPRITE_HEIGHT
    return `${
      (i * 100) / FRAMES_COUNT
    }% { transform: translate(${-x}px, ${-y}px); animation-timing-function: steps(1, end) }`
  })

const keyframes = `@keyframes tw-app-loading-screen__loadingAnimation {${frames.join(
  '\n  '
)}}`

let instance: {
  readonly update: (props: { status: string }) => void
  readonly remove: () => void
  onStatusChange: (status: string) => void
} | null = null

export function render(container: HTMLElement) {
  container.innerHTML = renderToStaticMarkup(<AppLoadingScreen />)

  const statusElement = container.querySelector<HTMLSpanElement>(
    '#app-loading-status'
  )!

  instance = {
    update(props: { status: string }) {
      if (statusElement.textContent !== String(props.status)) {
        statusElement.textContent = String(props.status)

        this.onStatusChange(String(props.status))
      }
    },

    remove() {
      container.remove()
    },

    onStatusChange(status: string) {
      void status
    },
  }
}

export function getInstance() {
  return instance!
}
