import dateLocalizer from 'date-fns/locale/en-US'

import data from './data.json'

export default {
  id: 'en-US',
  code: 'en',
  name: 'English',

  dateLocalizer,
  localeData: data,

  async load() {
    return {
      dateLocalizer,
      localeData: data,
    }
  },
} as const
