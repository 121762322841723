export default {
  code: 'de',
  id: 'de-DE',
  name: 'Deutsch',
  async load() {
    const [{ default: dateLocalizer }, { default: localeData }] =
      await Promise.all([import('date-fns/locale/de'), import('./data.json')])

    return {
      dateLocalizer,
      localeData,
    }
  },
} as const
