export default {
  code: 'it',
  id: 'it-IT',
  name: 'Italiano',
  async load() {
    const [{ default: dateLocalizer }, { default: localeData }] =
      await Promise.all([import('date-fns/locale/it'), import('./data.json')])

    return {
      dateLocalizer,
      localeData,
    }
  },
} as const
