export default {
  code: 'es',
  id: 'es-ES',
  name: 'Español',
  async load() {
    const [{ default: dateLocalizer }, { default: localeData }] =
      await Promise.all([import('date-fns/locale/es'), import('./data.json')])

    return {
      dateLocalizer,
      localeData,
    }
  },
} as const
