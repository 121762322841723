export default {
  code: 'cn',
  id: 'zh-CN',
  name: '中文(简体)',
  async load() {
    const [{ default: dateLocalizer }, { default: localeData }] =
      await Promise.all([
        import('date-fns/locale/zh-CN'),
        import('./data.json'),
      ])

    return {
      dateLocalizer,
      localeData,
    }
  },
} as const
